import { Typography } from 'components/ui/Typography'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  max-width: 660px;
  margin: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  button {
    width: max-content;
  }
`

export const StyledTypographyTitle = styled(Typography)`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -0.04em;
  color: rgb(30 54 86 / 50%);

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`

export const StyledTypographyDescription = styled(Typography)`
  line-height: 130%;
  letter-spacing: -0.04em;
`
