import { Box } from 'components/ui/Box'
import { Button } from 'components/ui/Button'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { StyledTypographyDescription, StyledTypographyTitle, Wrapper } from './styles'

interface Props {
  statusCode: number
}

export const ErrorPage: FC<Props> = ({ statusCode }) => {
  const theme = useTheme()
  const router = useRouter()
  const { t } = useTranslation('error')

  const handleButtonClick = () => void router.push('/')

  const renderErrorTemplate = () => (
    <>
      {statusCode === 500 ? (
        <div data-testid='server-error-template'>
          <Box marginBottom={20}>
            <StyledTypographyTitle>{t('server-error.title')}</StyledTypographyTitle>
          </Box>
          <StyledTypographyDescription color={theme.palette.core.DEEP_GRAY}>
            {t('server-error.description')}
          </StyledTypographyDescription>
        </div>
      ) : (
        <div data-testid='not-found-error-template'>
          <Box marginBottom={20}>
            <StyledTypographyTitle>{t('not-found-error.title')}</StyledTypographyTitle>
          </Box>
          <StyledTypographyDescription color={theme.palette.core.DEEP_GRAY}>
            {t('not-found-error.description')}
          </StyledTypographyDescription>
        </div>
      )}
    </>
  )

  return (
    <Wrapper>
      <Flex container direction='column' alignItems='center' rowGap={40}>
        <Flex container direction='column' justify='center'>
          <Box marginBottom={28}>
            <Icon fill={theme.palette.core.WHITE} icon={theme.icons.core.Error} />
          </Box>
          {renderErrorTemplate()}
        </Flex>
        <Button onClick={handleButtonClick} variant='flat' fill='outlined' data-testid='link-btn'>
          {t('btnText')}
        </Button>
      </Flex>
    </Wrapper>
  )
}
