import * as Sentry from '@sentry/nextjs'
import { ErrorPage } from 'components/pages/ErrorPage'
import { NextPageContext } from 'next/dist/shared/lib/utils'
import NextErrorComponent, { ErrorProps } from 'next/error'
import { FC } from 'react'

interface MyErrorProps extends ErrorProps {
  hasGetInitialPropsRun: boolean
  err: Error
}

const MyError: FC<MyErrorProps> = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err)
    // Flushing is not required in this case as it only happens on the client
  }

  return <ErrorPage statusCode={statusCode} />
}

export const getServerSideProps = async (context: NextPageContext) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context)

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (context.err) {
    Sentry.captureException(context.err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000)

    return errorInitialProps
  }

  return {
    props: {
      ...errorInitialProps,
      // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
      // getInitialProps has run
      hasGetInitialPropsRun: true,
    },
  }
}

export default MyError
